import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import FlatfileImporter from "flatfile-csv-importer";
import env from "../env";
import parser from "./parser";
import { CircularProgress, LinearProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHourglassHalf,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

dayjs.extend(quarterOfYear);

const GET_PLATFORMS = gql`
  query {
    platforms {
      hits {
        id
        pattern
      }
    }
  }
`;

function View({ question, context }) {
  const { data, loading, error } = useQuery(GET_PLATFORMS);
  const [doneCount, setDoneCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [allData, setAllData] = useState(null);

  useEffect(() => {
    if (!loading && data?.platforms?.hits?.length) {
      // Combine all platform patterns into one
      const regexStr = data.platforms.hits
        .map((platform) => platform.pattern)
        .join("|")
        .replace(/\//g, "\\/");

      try {
        // Create a combined regex from the platform patterns
        const listingRegex = new RegExp(regexStr, "gm");
        const fieldsConfig =
          question.input.fields.options || question.input.fields;
        const columnTypeKeys = {};

        const transformedFields = fieldsConfig.map((fieldConfig) => {
          const field = {
            alternates: [...fieldConfig.keys],
            label: fieldConfig.label,
            description: fieldConfig.description,
            validators: [],
          };

          field.key = field.alternates.shift();

          if (fieldConfig.type) {
            columnTypeKeys[field.key] = fieldConfig.type;
          }

          if (fieldConfig.required) {
            field.validators.push({
              validate: "required",
            });
          }

          switch (fieldConfig.type) {
            case "select":
              field.type = fieldConfig.type;
              field.matchStrategy = "exact";
              fieldConfig.options = parser.parse(fieldConfig.options, context);
              if (
                fieldConfig.options.length > 0 &&
                typeof fieldConfig.options[0] === "object"
              ) {
                field.options = fieldConfig.options;
              } else {
                field.options = fieldConfig.options.map((key) => ({
                  value: key,
                  label: String(key),
                }));
              }
              // Handle required and empty options
              if (fieldConfig.required && fieldConfig.options.length === 0) {
                delete field.type;
                field.validators.push({
                  validate: "regex_matches",
                  regex: "^$",
                  error: "Invalid option",
                });
              }
              break;

            case "boolean":
              field.type = "checkbox";
              field.validators.push({
                validate: "regex_matches",
                regex:
                  "/^$|^(1|0|yes|no|true|false|on|off|enabled|disabled)$/i",
                error: "Must be a boolean",
              });
              break;

            case "email":
              field.validators.push({
                validate: "regex_matches",
                regex:
                  '^(([^<>()\\[\\],;:\\s@]+(\\.[^<>()\\[\\],;:\\s@]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,}))$',
                error: "Must be an email address",
              });
              break;

            case "number":
              field.validators.push({
                validate: "regex_matches",
                regex: "^[0-9]+$",
                error: "Must be numeric",
              });
              break;

            case "telephone":
              field.validators.push({
                validate: "regex_matches",
                regex:
                  "^\\+?[0-9]{1,2} ?\\(?[0-9]{3}[\\)-]? ?[0-9]{3}-? ?[0-9]{2,4}$",
                error: "Must be a valid telephone number",
              });
              break;

            case "address":
              field.validators.push({
                validate: "regex_matches",
                regex: "^.*,.*,.*$",
                error: "Must be a comma separated address",
              });
              break;

            case "listings":
              field.validators.push({
                validate: "regex_matches",
                regex: listingRegex.source,
                error: "Must be a supported listing URL",
              });
              break;

            case "period": {
              const dateFormat = "MMMM D, YYYY";
              field.type = "select";
              field.matchStrategy = "exact";
              fieldConfig.options = parser.parse(fieldConfig.options, context);
              field.options = fieldConfig.options.map((key) => {
                const alternates = [
                  `${key.start_at} ${key.end_at}`,
                  `${key.start_at} - ${key.end_at}`,
                  `${dayjs(key.start_at).format(dateFormat)} ${dayjs(
                    key.end_at
                  ).format(dateFormat)}`,
                  `${dayjs(key.start_at).format(dateFormat)} - ${dayjs(
                    key.end_at
                  ).format(dateFormat)}`,
                  `${dayjs(key.start_at).format("M-D-YYYY")} ${dayjs(
                    key.end_at
                  ).format("M-D-YYYY")}`,
                  `${dayjs(key.start_at).format("M-D-YYYY")} - ${dayjs(
                    key.end_at
                  ).format("M-D-YYYY")}`,
                  `${dayjs(key.start_at).format("MM-DD-YYYY")} ${dayjs(
                    key.end_at
                  ).format("MM-DD-YYYY")}`,
                  `${dayjs(key.start_at).format("MM-DD-YYYY")} - ${dayjs(
                    key.end_at
                  ).format("MM-DD-YYYY")}`,
                  `${dayjs(key.start_at).format("D-M-YYYY")} ${dayjs(
                    key.end_at
                  ).format("D-M-YYYY")}`,
                  `${dayjs(key.start_at).format("D-M-YYYY")} - ${dayjs(
                    key.end_at
                  ).format("D-M-YYYY")}`,
                  `${dayjs(key.start_at).format("DD-MM-YYYY")} ${dayjs(
                    key.end_at
                  ).format("DD-MM-YYYY")}`,
                  `${dayjs(key.start_at).format("DD-MM-YYYY")} - ${dayjs(
                    key.end_at
                  ).format("DD-MM-YYYY")}`,
                  `${dayjs(key.start_at).format("M/D/YYYY")} ${dayjs(
                    key.end_at
                  ).format("M/D/YYYY")}`,
                  `${dayjs(key.start_at).format("M/D/YYYY")} - ${dayjs(
                    key.end_at
                  ).format("M/D/YYYY")}`,
                  `${dayjs(key.start_at).format("MM/DD/YYYY")} ${dayjs(
                    key.end_at
                  ).format("MM/DD/YYYY")}`,
                  `${dayjs(key.start_at).format("MM/DD/YYYY")} - ${dayjs(
                    key.end_at
                  ).format("MM/DD/YYYY")}`,
                  `${dayjs(key.start_at).format("D/M/YYYY")} ${dayjs(
                    key.end_at
                  ).format("D/M/YYYY")}`,
                  `${dayjs(key.start_at).format("D/M/YYYY")} - ${dayjs(
                    key.end_at
                  ).format("D/M/YYYY")}`,
                  `${dayjs(key.start_at).format("DD/MM/YYYY")} ${dayjs(
                    key.end_at
                  ).format("DD/MM/YYYY")}`,
                  `${dayjs(key.start_at).format("DD/MM/YYYY")} - ${dayjs(
                    key.end_at
                  ).format("DD/MM/YYYY")}`,
                ];

                let label;

                if (
                  dayjs(key.start_at).month() !== dayjs(key.end_at).month() &&
                  dayjs(key.start_at).quarter() === dayjs(key.end_at).quarter()
                ) {
                  // Quarter
                  alternates.push(
                    `Q${dayjs(key.start_at).quarter()} ${dayjs(
                      key.start_at
                    ).year()}`
                  );
                  alternates.push(
                    `${dayjs(key.start_at).year()} Q${dayjs(
                      key.start_at
                    ).quarter()}`
                  );
                  label = `${dayjs(key.start_at).year()} Q${dayjs(
                    key.start_at
                  ).quarter()}`;
                } else if (
                  dayjs(key.start_at).quarter() !==
                    dayjs(key.end_at).quarter() &&
                  dayjs(key.start_at).year() === dayjs(key.end_at).year()
                ) {
                  // Annual
                  alternates.push(dayjs(key.start_at).year());
                  label = dayjs(key.start_at).year();
                } else {
                  // Date Range
                  label = `${dayjs(key.start_at).format(dateFormat)} - ${dayjs(
                    key.end_at
                  ).format(dateFormat)}`;
                }

                return {
                  value: JSON.stringify(key),
                  label: label,
                  alternates: alternates,
                };
              });

              field.validators.push({
                validate: "regex_matches",
                regex: "^.*start_at",
                error: "Must be a period",
              });
              break;
            }

            default:
              break;
          }

          return field;
        });

        FlatfileImporter.setVersion(2);

        const importer = new FlatfileImporter(env.FLATFILE_LICENSE_KEY, {
          type: "Spreadsheet",
          fields: transformedFields,
          managed: true,
          title: parser.interpolate(question.properties.locale.title, context),
          allowCustom: false,
          maxRecords: question.input.max || 10000,
          devMode: process.env.REACT_APP_ENVIRONMENT !== "production",
          allowInvalidSubmit: false,
          styleOverrides: {
            borderRadius: "5px",
            primaryButtonColor: "#4caf50",
            linkColor: "#4caf50",
            primaryTextColor: "#333333",
            fontFamily: "Roboto, Helvetica Neue, sans-serif",
          },
        });

        importer.registerRecordHook(function (record) {
          const processedRecord = {};

          Object.entries(columnTypeKeys).forEach(([key, type]) => {
            switch (type) {
              case "boolean":
                processedRecord[key] = {
                  value: ["true", "y", "yes", "on", "enabled"].includes(
                    String(record[key]).toLowerCase().trim()
                  ),
                  info: [
                    {
                      message: "Cast to Boolean",
                      level: "info",
                    },
                  ],
                };
                break;

              case "email":
                if (record[key] !== record[key].trim()) {
                  processedRecord[key] = {
                    value: record[key].trim(),
                    info: [
                      {
                        message: "Trimmed whitespace",
                        level: "info",
                      },
                    ],
                  };
                }
                break;

              case "number": {
                const value = Number(record[key]);
                processedRecord[key] = {
                  value: isNaN(value) ? record[key] : value,
                  info: [
                    {
                      message: "Cast to Number",
                      level: isNaN(value) ? "error" : "info",
                    },
                  ],
                };
                break;
              }

              default:
                break;
            }
          });

          return processedRecord;
        });

        importer.setCustomer({
          userId: parser.interpolate(question.input.user_id, context.get()),
        });

        importer
          .requestDataFromUser()
          .then(
            (res) => {
              // Deep clone the original value once before the loop starts
              const transformedData = res.validData.map((item) => {
                const updatedItem = { ...item };

                // Processing logic
                Object.keys(columnTypeKeys).forEach((key) => {
                  const type = columnTypeKeys[key];
                  if (updatedItem[key]) {
                    switch (type) {
                      case "period":
                        updatedItem[key] = JSON.parse(updatedItem[key]);
                        break;

                      case "listings":
                        updatedItem[key] = updatedItem[key]
                          .split(",")
                          .map((url) => {
                            const platform = data.platforms.hits.find((o) =>
                              url.trim().match(new RegExp(o.pattern))
                            );
                            return {
                              type: platform ? platform.id : "OTHER",
                              value: url,
                            };
                          });
                        break;

                      default:
                        break;
                    }
                  }
                });

                let payload = { item: updatedItem };

                // Ensure parsing only happens if question.input.save and question.input.save.variables exist
                if (question.input?.save?.variables) {
                  const originalVariables = JSON.parse(
                    JSON.stringify(question.input.save.variables)
                  );

                  const temp = JSON.parse(JSON.stringify(originalVariables));
                  if (temp) {
                    context.assign({ item: updatedItem });
                    const parsedPayload = parser.parse(temp, context);
                    payload.item = cleanRecursive(parsedPayload.item);
                  }
                }

                return payload;
              });

              setAllData(transformedData);

              importer.close();

              // Save to Answers
              if (question.properties.answer_key) {
                const key = parser.interpolate(
                  question.properties.answer_key,
                  context
                );

                context.del(`answers.${key}`);
                const items = transformedData.map((data) => data.item);
                context.assign({ [`answers.${key}`]: items });
              }

              // Save logic
              if (question.input.save) {
                switch (question.input.method) {
                  case "parallel":
                    transformedData.forEach((item) => {
                      fetch(context.get().config.API_URL, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                          query: question.input.save.query,
                          variables: item,
                        }),
                      })
                        .then((response) => response.json())
                        .then(() => {
                          // Handle success
                          item.status = "COMPLETE";
                          setDoneCount((prev) => prev + 1);
                          setProgress(
                            Math.max(
                              0,
                              Math.min(
                                100,
                                Math.round(
                                  (doneCount /
                                    (res.stats.$meta.count_rows_accepted - 1)) *
                                    100
                                )
                              )
                            )
                          );
                        })
                        .catch((error) => {
                          item.status = "ERROR";
                          item.errors = error;
                          setDoneCount((prev) => prev + 1);
                          setProgress(
                            Math.max(
                              0,
                              Math.min(
                                100,
                                Math.round(
                                  (doneCount /
                                    (res.stats.$meta.count_rows_accepted - 1)) *
                                    100
                                )
                              )
                            )
                          );
                        });
                    });
                    break;

                  case "batch": {
                    const partStart = question.input.save.query.substr(
                      0,
                      question.input.save.query.indexOf("{") + 1
                    );
                    const partNth = question.input.save.query.substr(
                      partStart.length,
                      question.input.save.query.length - partStart.length - 1
                    );

                    const variables = {};
                    let mutationQuery = "mutation(";

                    transformedData.forEach((item, index) => {
                      mutationQuery += `$item${index}: EditListing! `;
                      variables[`item${index}`] = item.item;
                    });

                    mutationQuery += ") { ";
                    transformedData.forEach((item, index) => {
                      mutationQuery += `id${index}: ${partNth
                        .replace("$id", `"${item.id}"`)
                        .replace("$item", `$item${index}`)} `;
                    });
                    mutationQuery += "}";

                    fetch(context.get().config.API_URL, {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        query: mutationQuery,
                        variables: variables,
                      }),
                    })
                      .then((response) => response.json())
                      .then((response) => {
                        transformedData.forEach((item, index) => {
                          if (response.data[`id${index}`]) {
                            item.status = "COMPLETE";
                          } else {
                            item.status = "ERROR";
                            item.errors = response.errors || [];
                          }
                          setDoneCount((prev) => prev + 1);
                        });
                      })
                      .catch((error) => {
                        transformedData.forEach((item) => {
                          if (!item.status) {
                            item.status = "ERROR";
                            item.errors = [error];
                          }
                          setDoneCount((prev) => prev + 1);
                        });
                      });
                    break;
                  }

                  case "mock":
                    transformedData.forEach((item) => {
                      item.status = "COMPLETE";
                      setDoneCount((prev) => prev + 1);
                      setProgress(
                        Math.max(
                          0,
                          Math.min(
                            100,
                            Math.round(
                              (doneCount /
                                (res.stats.$meta.count_rows_accepted - 1)) *
                                100
                            )
                          )
                        )
                      );
                    });
                    break;

                  case "serial":
                  default:
                    transformedData.forEach((item) => {
                      fetch(context.get().config.API_URL, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                          query: question.input.save.query,
                          variables: item,
                        }),
                      })
                        .then((response) => response.json())
                        .then(() => {
                          item.status = "COMPLETE";
                          setDoneCount((prev) => prev + 1);
                          setProgress(
                            Math.max(
                              0,
                              Math.min(
                                100,
                                Math.round(
                                  (doneCount /
                                    (res.stats.$meta.count_rows_accepted - 1)) *
                                    100
                                )
                              )
                            )
                          );
                        })
                        .catch((error) => {
                          item.status = "ERROR";
                          item.errors = error;
                          setDoneCount((prev) => prev + 1);
                          setProgress(
                            Math.max(
                              0,
                              Math.min(
                                100,
                                Math.round(
                                  (doneCount /
                                    (res.stats.$meta.count_rows_accepted - 1)) *
                                    100
                                )
                              )
                            )
                          );
                        });
                    });
                    break;
                }
              } else {
                for (const item of transformedData) {
                  item.status = "COMPLETE";
                  setDoneCount((prev) => prev + 1);
                  setProgress(
                    Math.max(
                      0,
                      Math.min(
                        100,
                        Math.round(
                          (doneCount /
                            (res.stats.$meta.count_rows_accepted - 1)) *
                            100
                        )
                      )
                    )
                  );
                }
              }

              setAllData(transformedData);
            },
            () => {
              window.history.back();
            }
          )
          .catch((error) => {
            console.error("Error during data import:", error);
            importer.close();
          });
      } catch (err) {
        console.error("Error constructing regex:", err);
      }
    }
  }, [data, loading]);

  function cleanRecursive(payload) {
    if (typeof payload === "string" && payload.startsWith("{")) {
      try {
        payload = JSON.parse(payload);
      } catch (e) {
        return null;
      }
    }

    if (typeof payload === "object" && payload !== null) {
      Object.keys(payload).forEach((key) => {
        const val = payload[key];

        if (typeof val === "string" && val.startsWith("{")) {
          try {
            payload[key] = JSON.parse(val);
          } catch (e) {
            delete payload[key];
            return;
          }
        }

        if (typeof val === "object" && val !== null) {
          if (cleanRecursive(val) === null) {
            delete payload[key];
          }
        } else if (val === null || val === "") {
          delete payload[key];
        }
      });
    }

    return payload;
  }

  if (loading || !allData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress sx={{ color: "rgb(255, 82, 64)" }} />
      </div>
    );
  }
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      {allData && (
        <>
          <LinearProgress
            sx={{
              backgroundColor: "lightgray",
              "& .MuiLinearProgress-bar": { backgroundColor: "#FF5240" },
            }}
            variant="determinate"
            value={progress}
          />
          <div className="import-list">
            <ul
              style={{ display: "flex", flexDirection: "column", padding: 0 }}
            >
              {allData.map((item, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: parser.interpolate(
                          question.properties.locale.label,
                          item
                        ),
                      }}
                    ></h3>
                    {question.properties.locale.label_more && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: parser.interpolate(
                            question.properties.locale.label_more,
                            item
                          ),
                        }}
                      ></p>
                    )}
                    {item.$errors && (
                      <p className="error" style={{ color: "red" }}>
                        {item.$errors.map((e, i) => (
                          <span key={i}>
                            {e.message}
                            <br />
                          </span>
                        ))}
                      </p>
                    )}
                  </div>
                  {item.status === "COMPLETE" && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  )}
                  {item.status === "ERROR" && (
                    <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                  )}
                  {["COMPLETE", "ERROR"].indexOf(item.status) === -1 && (
                    <FontAwesomeIcon
                      icon={faHourglassHalf}
                      style={{ color: "orange" }}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

export default View;
