import "./SearchView.scss";

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Alert, Paper, Snackbar } from "@mui/material";
import { loader } from "graphql.macro";
import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import qs, { ParsedQs } from "qs";
import { LoadingScreen } from "../../common/loading";

import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterItem,
  GridFilterModel,
  GridLinkOperator,
  GridPinnedColumns,
  GridSelectionModel,
  GridSortDirection,
  GridSortModel,
  GridState,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import SearchViewToolbar from "./SearchViewToolbar";
import DataGridCustomToolbar from "../DataGridCustomToolbar/DataGridCustomToolbar";
import {
  EditableFieldOption,
  TableMetaFilter,
  TableMetaFilters,
  TableEditableField,
  TableView,
  userSettingsQuery,
} from "../../dashboard/common/data-grid-cols";
import useDebounce from "../../dashboard/common/debounceHook";
import {
  renderRentalUnitCell,
  renderHtmlfields,
  renderBooleanValues,
  renderDateOrTimeValues,
  renderLastReportedPeriod,
  renderPropertyPage,
  renderEstimatedTaxBasis,
  renderRentalUnitIdsCell,
  renderLetterId,
  renderPropertyPages,
  renderTrackingNumber,
  renderRevenueBreakdown,
  renderComplaintType,
  renderUploadcareCDN,
  renderCallRecording,
  renderEditableStatusField,
  renderEditablePermitNumberField,
  renderAirbnbHostID,
  renderDeptLastAction,
  renderEditHistoryLink,
  renderEditHistoryPageLink,
  renderRegistrationPendingStatus,
  renderUploadedDocuments,
  renderRegistrationUpdateLink,
  renderAirbnbHostIDForRentalUnitsGrid,
  renderAuditManagementStatus,
  renderViolationType,
  renderCivilViolationNoticeIssued,
  renderCourtesyWarningNoticeIssued,
} from "../../dashboard/common/data-grid-cells";
import HTMLColumns from "./HtmlColumns.json";
import CurrencyColumns from "./CurrencyColumns.json";
import DateAndTimeColumns from "./DateAndTimeColumns.json";
import {
  currency_formatter,
  extractContent,
  titleCase,
} from "../../dashboard/common/utils";
import { ListingsCell } from "./ListingsCell";
import CommentCellRenderer from "../../dashboard/common/renderer/CommentCellRenderer";
import GridToolBarFilter from "../DataGridCustomToolbar/GridToolbarFilter";
import { ReportIssue } from "../../dashboard/letters/ReportIssue/ReportIssue";
import { ReportIssueModalCell } from "../../dashboard/letters/ReportIssue/ReportIssueModalCell/ReportIssueModalCell";
import { RefundModalCell } from "../../dashboard/payments/RefundModal/RefundModalCell/RefundModalCell";
import RefundModalBody from "../../dashboard/payments/RefundModal/RefundModalCell/RefundModalBody";
import { useAuth0 } from "@auth0/auth0-react";
import env from "../../common/env";
import { ViewValue } from "../../dashboard/letters/commons/Models";

interface SearchViewProps {
  Item?: any;
  variables?: any;
  searchQuery: any;
  columns: Array<any | GridColDef>;
  filters: TableMetaFilters[];
  title: string;
  commentIdColumn?: string;
  editableFieldOptions?: TableEditableField[];
  tableType?: string;
}

type SnackbarType = "error" | "success" | "info" | "warning";

export interface SnackbarData {
  horizontal?: "left" | "center" | "right";
  vertical?: "top" | "bottom";
  message: string;
  type: SnackbarType;
}

const generateExportMutation = loader(
  "../DataGridCustomToolbar/Export.graphql"
);

const generateExportMutationForRevenueEstimate = loader(
  "../DataGridCustomToolbar/RevenueEstimateExport.graphql"
);

const createOrEditViewMutation = loader(
  "../DataGridCustomToolbar/createOrEditView.graphql"
);

const deleteViewMutation = loader(
  "../DataGridCustomToolbar/deleteView.graphql"
);

const templatesQuery = loader(
  "../../dashboard/rental-units/Letter/LetterTemplates.graphql"
);

const bulkLettersCriteriaQuery = loader(
  "../../dashboard/letters/BulkLettersCriteria.graphql"
);

const taskBulkLettersCriteriaResultQuery = loader(
  "../../dashboard/letters/TaskBulkLettersCriteriaResult.graphql"
);

const createOrUpdateStatusMutation = loader("./createOrEditStatus.graphql");

const taskDetailsQuery = loader("../../dashboard/letters/TaskDetails.graphql");

const DEFAULT_PAGE_SIZE = 20;
const AND_FILTERS = [
  "Advertised and meets STR definition",
  "Identified, advertised STR",
  "Recently posted and not yet identified",
  "Non-compliant and has not yet received a letter",
  "Non-compliant or unknown compliance and identified in the last 30 days",
  "Has received a letter and is now in compliance",
  "Successful payments made through Host Compliance",
  "Pending payments made through Host Compliance",
];

function SearchView({
  title,
  columns,
  filters,
  searchQuery,
  commentIdColumn,
  variables,
  editableFieldOptions,
  tableType,
}: SearchViewProps) {
  document.title = `${title}`;
  const { user } = useAuth0();
  let geoid: any;
  if (user) {
    geoid = user[env.AUTH0_METADATA_KEY].geoid;
  }
  const [showRentalUnitSelectionSection, setShowRentalUnitSelectionSection] =
    useState<boolean>(false);
  const [showRentalUnitsSelectedText, setShowRentalUnitsSelectedText] =
    useState<boolean>(true);
  const [showSelectAllRentalUnitsCTA, setShowSelectAllRentalUnitsCTA] =
    useState<boolean>(true);
  const [showAllRentalUnitsSelectedText, setShowAllRentalUnitsSelectedText] =
    useState<boolean>(false);
  const [
    showClearRentalUnitsSelectionCTA,
    setShowClearRentalUnitsSelectionCTA,
  ] = useState<boolean>(false);
  const [isAllRentalUnitsSelected, setIsAllRentalUnitsSelected] =
    useState<boolean>(false);

  const gridRef = useGridApiRef();
  const location = useLocation<Location>();
  const history = useHistory<History>();
  const queryParams: ParsedQs = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [storedColumns, setStoredColumns] = useState<Array<any | GridColDef>>(
    []
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openTemplatesMenu = Boolean(anchorEl);

  const [pinnedColumns, setPinnedColumns] = useState<any>({
    left: [],
    right: [],
  });
  const [searchValue, setSearchValue] = useState<string>(
    String(queryParams.q || "")
  );
  const [operator, setOperator] = useState<string>(
    String(queryParams.operator?.toString().toUpperCase() || "OR")
  );
  const [pageSize, setPageSize] = useState<number>(
    parseInt(String(queryParams.size)) || DEFAULT_PAGE_SIZE
  );
  const [pageNumber, setPageNumber] = useState<number>(
    parseInt(String(queryParams.page)) || 0
  );
  const [sortColumn, setSortColumn] = useState<string>(
    String(queryParams.sort || "")
  );
  const [searchInputValue, setSearchInputValue] = useState<string>(searchValue);
  const debouncedSearchTerm = useDebounce(searchValue, 1000);
  const [preDefinedFilter, setPreDefinedFilter] = useState<string>(
    String(queryParams.preDefinedFilter || "")
  );
  const [selectedView, setSelectedView] = useState<string>(
    String(queryParams.selectedView || "")
  );
  const [query, setQuery] = useState<string>("");
  const [snackbarData, setSnackbarData] = useState<SnackbarData>({
    message: "",
    type: "success",
  });
  const [trigger, setTrigger] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<GridFilterItem[]>( // @ts-ignore
    queryParams.filters?.map((filterItem, index) => ({
      id: index,
      columnField: filterItem.columnField,
      operatorValue: filterItem.operatorValue,
      value: filterItem.value,
    })) || []
  );

  const [initialData, setInitialData] = useState<any>({});

  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [selectedTemplate, setSelectedTemplate] = useState<any>({
    letter_template_id: "",
    customer_display_name: "",
  });

  const [views, setViews] = useState<TableView[] | undefined>([]);

  const [eligibilityQueryLoading, setEligibilityQueryLoading] =
    useState<boolean>(false);
  const [loadingForReportIssue, setLoadingForReportIssue] =
    useState<boolean>(false);
  const [eligibilityData, setEligibilityData] = useState<any>(null);
  const [density, setDensity] = useState<GridDensity>(
    (localStorage.getItem("density") as GridDensity) || "standard"
  );

  const handleStateChange = (state: GridState) => {
    const newDensity = state.density.value;
    if (newDensity !== density) {
      localStorage.setItem("density", newDensity);
      setDensity(newDensity);
    }
  };

  const { refetch: refetechEligibilityQuery } = useQuery(
    bulkLettersCriteriaQuery,
    {
      skip: true,
      onError: (error: ApolloError) => {
        setSelectedTemplate({});
        setSnackbarData({
          message: error.message,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        handleClose();
      },
    }
  );

  const { refetch: refetchViews } = useQuery(userSettingsQuery, {
    variables: {
      query: `key:com.hostcompliance.react.filters.${titleCase(title)}.*`,
    },
    onCompleted: (data) => {
      let viewsData = data?.userSettings?.hits;
      setViews(viewsData);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });

  const { data: letterTemplates } = useQuery(templatesQuery, {
    variables: {
      rental_unit_ids: null,
    },
  });

  const reRenderTriggers = [
    debouncedSearchTerm,
    selectedFilters,
    operator,
    pageSize,
    pageNumber,
    sortColumn,
    columns.length,
    preDefinedFilter,
    trigger,
  ];

  let dataToShow: { [key: string]: any }[] = [];
  let rowCount = 0;
  const [getData, { data, loading, error }] = useLazyQuery(
    searchQuery(storedColumns, commentIdColumn),
    {
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [generateExport, { loading: exportLoading }] = useMutation(
    title === "Revenue Estimate"
      ? generateExportMutationForRevenueEstimate
      : generateExportMutation
  );

  const [createOrEditView] = useMutation(createOrEditViewMutation);
  const [deleteView] = useMutation(deleteViewMutation);
  const [createOrUpdateStatus] = useMutation(createOrUpdateStatusMutation);

  const handleFetchViews = () => {
    refetchViews();
  };

  //function that escapes special characters in a filter value (for example, filters that have URLs as their value)
  const escapeSpecialCharacters = (value: string) => {
    return value
      .replace(/\\/g, "\\\\") // Escape backslashes
      .replace(/"/g, '\\"'); // Escape double quotes
  };

  const prepareFiltersToQuery = (): string => {
    // Convert filters list into a lucene query string
    return selectedFilters
      .map((filter: any) => {
        if (filter.operatorValue === "isBetweenDates" && filter.value) {
          const fromDate = filter.value.fromDate;
          const toDate = filter.value.toDate;
          return `(${filter.columnField}:[${fromDate} TO ${toDate}])`;
        } else {
          let isBoolean = isBooleanField(filter.columnField);
          return `(${filter.value
            .map((value: any) => {
              if (isBoolean) {
                return `${filter.columnField}:${value}`;
              } else {
                //checking if filter parameter needs to be ignored while querying
                if (value === "N/A") {
                  return `NOT ${filter.columnField}: *`;
                } else {
                  const escapedValue = escapeSpecialCharacters(value); //escape all special characters in filter value to form a valid string
                  return `${filter.columnField}:"${escapedValue}"`;
                }
              }
            })
            .join(" OR ")})`;
        }
      })
      .join(` ${operator} `);
  };

  const getOrderdColumns = (
    columnsReceived: GridColDef[],
    localColumns: GridColDef[],
    onLoad: boolean
  ): GridColDef[] => {
    let formattedStoredColumns = localColumns.map((localStoredColumn) => {
      const backendColumn: GridColDef | undefined = columnsReceived.find(
        (obj) => obj["field"] === localStoredColumn["field"]
      );
      let value = { ...backendColumn, ...localStoredColumn };
      value.hide = onLoad
        ? localStoredColumn.hide
        : backendColumn !== undefined
        ? backendColumn.hide
        : true;
      value.width = onLoad
        ? localStoredColumn.width
        : backendColumn !== undefined
        ? backendColumn.width
        : 100;
      return value;
    });
    let missingElements = columnsReceived.filter(function (element) {
      // Adding new columns that might comeup from BE into localstorage
      const matchedColumn: GridColDef | undefined = formattedStoredColumns.find(
        (obj) => obj["field"] === element["field"]
      );
      if (matchedColumn === undefined) {
        return true;
      }
      return false;
    });
    return formattedStoredColumns.concat(missingElements);
  };

  useEffect(() => {
    if (!loading) {
      const formattedColumns: GridColDef[] = columns.map((column) => {
        if (column.field === "external_property_id") {
          column.renderCell = renderRentalUnitCell();
        } else if (column.field === "add_view_comments_modal") {
          column.renderCell = (params: GridCellParams) => (
            <CommentCellRenderer params={params} />
          );
        } else if (column.field === "listings") {
          column.renderCell = (params: GridCellParams) => (
            <ListingsCell params={params} />
          );
        } else if (column.field === "last_reported_revenue_period") {
          column.valueGetter = renderLastReportedPeriod();
        } else if (column.field === "property_page") {
          column.renderCell = renderPropertyPage(title);
        } else if (column.field === "property_pages") {
          column.renderCell = renderPropertyPages();
        } else if (column.field === "internal_listing_id") {
          column.renderCell = renderPropertyPage(title, true);
        } else if (column.field === "property_ids") {
          column.renderCell = renderRentalUnitIdsCell();
        } else if (column.field === "revenue_breakdown") {
          column.renderCell = renderRevenueBreakdown();
        } else if (title === "Letters" && column.field === "id") {
          column.renderCell = renderLetterId(dataToShow);
        } else if (column.field === "complaint_type") {
          column.renderCell = renderComplaintType();
        } else if (column.field === "uploadcare_cdn") {
          column.renderCell = renderUploadcareCDN();
        } else if (column.field === "call_recording") {
          column.renderCell = renderCallRecording();
        } else if (column.field === "case_status_description_department") {
          column.renderCell = renderDeptLastAction(editableFieldOptions);
        } else if (title === "Listings" && column.field === "airbnb_host_id") {
          column.renderCell = renderAirbnbHostID();
        } else if (
          title === "Rental Units" &&
          column.field === "airbnb_host_id"
        ) {
          column.renderCell = renderAirbnbHostIDForRentalUnitsGrid();
        } else if (column.field === "history") {
          column.renderCell = renderEditHistoryLink();
        } else if (column.field === "history_page") {
          column.renderCell = renderEditHistoryPageLink();
        } else if (column.field === "mark_payment_as_returned_modal") {
          column.renderCell = (params: GridCellParams) => {
            return (
              <RefundModalCell
                rowParams={params}
                title="Mark payment as returned or refunded"
                cellText="Mark payment as returned or refunded"
                geoid={geoid}
              >
                <RefundModalBody
                  row={params.row}
                  setSnackbarData={setSnackbarData}
                  reloadGrid={() => setTrigger(!trigger)}
                />
              </RefundModalCell>
            );
          };
        } else if (HTMLColumns["html"].indexOf(column.field) > -1) {
          column.renderCell = renderHtmlfields("html");
        } else if (HTMLColumns["urls"].indexOf(column.field) > -1) {
          column.renderCell = renderHtmlfields("url");
        } else if (
          column.fieldType === "date" ||
          DateAndTimeColumns["date"].indexOf(column.field) > -1
        ) {
          column.valueGetter = renderDateOrTimeValues("date");
        } else if (
          column.fieldType === "datetime" ||
          DateAndTimeColumns["datetime"].indexOf(column.field) > -1
        ) {
          column.valueGetter = renderDateOrTimeValues("datetime");
        } else if (
          column.field === "estimated_tax_basis" ||
          column.field === "lob_status"
        ) {
          column.valueGetter = renderEstimatedTaxBasis();
        } else if (column.field === "documentation") {
          column.renderCell = renderUploadedDocuments();
        } else if (column.field === "edit_letter_modal") {
          column.renderCell = (params: GridCellParams) => {
            return (
              <ReportIssueModalCell
                rowParams={params}
                title="Report Issue"
                cellText="Report Issue"
                showLoading={() => setLoadingForReportIssue(true)}
                stopLoading={() => setLoadingForReportIssue(false)}
                setSnackbarData={setSnackbarData}
                reloadGrid={() => setTrigger(!trigger)}
              >
                <ReportIssue
                  row={params.row}
                  setSnackbarData={setSnackbarData}
                  reloadGrid={() => setTrigger(!trigger)}
                />
              </ReportIssueModalCell>
            );
          };
        } else if (
          CurrencyColumns["currency_columns"].indexOf(column.field) > -1
        ) {
          column.renderCell = (params: GridCellParams) => {
            if (params.field === "reported_revenue" && params.value === null) {
              return "None reported";
            } else {
              return currency_formatter.format(params.value);
            }
          };
        } else if (column.field === "tracking_number") {
          column.renderCell = renderTrackingNumber();
        }
        if (column.fieldType === "boolean" || column.fieldType === "bool") {
          column.valueGetter = renderBooleanValues();
        }
        if (column.field === "external_property_id") {
          column.renderHeader = () => (
            <span style={{ fontWeight: 500 }}>Rental Unit</span>
          );
        }
        if (
          (column.field === "email_type" || column.field === "status") &&
          title === "Emails"
        ) {
          column.valueGetter = (params: GridCellParams) => {
            return params.value ? params.value.toLowerCase() : "";
          };
        }
        if (column.field === "status" && title === "Registrations") {
          const reloadGrid = () => {
            setTrigger(!trigger);
          };
          column.renderCell = renderRegistrationPendingStatus(
            setSnackbarData,
            reloadGrid
          );
        }
        if (column.field === "state" && title === "Audit Management") {
          column.renderCell = renderAuditManagementStatus();
        }
        if (column.field === "update" && title === "Registrations") {
          column.renderCell = renderRegistrationUpdateLink();
        }
        if (title === "Rental Units" && geoid === "05000US12086u") {
          //only for Miami-Dade County
          if (column.field === "violation_type") {
            column.renderCell = renderViolationType(
              column.headerName,
              handleEditableFieldUpdate
            );
          } else if (column.field === "civil_violation_notice_issued") {
            column.renderCell = renderCivilViolationNoticeIssued(
              column.headerName,
              handleEditableFieldUpdate
            );
          } else if (column.field === "courtesy_warning_notice_issued") {
            column.renderCell = renderCourtesyWarningNoticeIssued(
              column.headerName,
              handleEditableFieldUpdate
            );
          }
        }
        editableFieldOptions?.forEach((editableField) => {
          if (column.field === editableField.field) {
            if (column.field === "permit_number") {
              column.renderCell = renderEditablePermitNumberField(
                editableField,
                column.headerName,
                handleEditableFieldUpdate
              );
            } else {
              column.renderCell = renderEditableStatusField(
                editableField,
                column.headerName,
                handleEditableFieldUpdate
              );
            }
          }
        });
        return column;
      });
      const USER_PREFERENCE_FLAG = "isUserPreferenceAvailable";
      const fixedWidthCols = ["external_property_id", "property_page"];
      const localData: string | null = localStorage.getItem(
        location.pathname.replace(/\/$/, "")
      );
      const userPreference: string | null =
        localStorage.getItem(USER_PREFERENCE_FLAG);
      // if local data is present on load
      if (localData && !userPreference) {
        // Combining values stored in local storage and backend columns.
        const initialStoredColumns: GridColDef[] =
          JSON.parse(localData)["columnsDefinition"];
        const pinnedColumns: GridPinnedColumns =
          JSON.parse(localData)["pinnedColumns"];
        setPinnedColumns(pinnedColumns);
        if (formattedColumns.length > 0) {
          let formattedStoredColumns = getOrderdColumns(
            formattedColumns,
            initialStoredColumns,
            true
          );
          setStoredColumns(formattedStoredColumns);
        }
      } else {
        // Initial localStorage columns definition store with calculated width as per longest value in cell
        localStorage.setItem(USER_PREFERENCE_FLAG, "False");
        if (Object.keys(initialData).length != 0) {
          const fieldWidthMapping: { field: string; width: any }[] = [];

          // Get all the keys to be shown in the grid
          const visibleFieldsInGrid = Object.keys(initialData);

          // pluck values for each field and get the longest value for all
          visibleFieldsInGrid.forEach((field) => {
            const values = pluckValuesForKey(dataToShow, field);
            const longest = longestValueInCell(values);
            let width = getWidthFortheColumn(field, longest.length);
            fieldWidthMapping.push({ field: field, width: width });
          });

          // Map the column definition as per the longest calculated width
          let updatedWidthCols: GridColDef[] = [];
          fieldWidthMapping.forEach((calculatedMapping) => {
            updatedWidthCols = formattedColumns.map((col) => {
              if (col.field === calculatedMapping.field) {
                if (fixedWidthCols.indexOf(col.field) > -1) {
                  col.width = calculatedMapping.width; // getting the width from getWidthFortheColumn() function
                } else {
                  col.width =
                    calculatedMapping.width !== 0 &&
                    col.width &&
                    col.width < calculatedMapping.width
                      ? calculatedMapping.width
                      : col.width;
                }
              }
              return col;
            });
          });
          // set in state variable (storedColumns)
          setStoredColumns(updatedWidthCols);

          // Mark user prefernce to True
          localStorage.setItem(USER_PREFERENCE_FLAG, "True");
        } else if (
          !loading &&
          Object.keys(initialData).length === 0 &&
          requestedData
        ) {
          // set in state variable (storedColumns)
          setStoredColumns(formattedColumns);
        }
      }
    }
  }, [columns, initialData, loading, editableFieldOptions]);

  useEffect(() => {
    if (
      selectionModel.length < pageSize &&
      selectionModel.length !== rowCount
    ) {
      resetSelectionSection();
    }
  }, [selectionModel]);

  // TODO: Delete filtering of add_view_comments_modal as soon as comments are implemented in letters
  useEffect(() => {
    if (title === "Letters")
      setStoredColumns(
        storedColumns.filter(
          (item) =>
            item.field !== "add_view_comments_modal" &&
            item.field !== "property_pages" &&
            item.field !== "lob_pdf_url"
        )
      );
  }, []);

  useEffect(() => {
    if (!columns.length) {
      return;
    }
    setSearchInputValue(searchValue);
    const q = `${prepareFiltersToQuery()}`.trim();
    setQuery(q);
    getData({
      variables: {
        q: q,
        size: pageSize,
        phrase: searchValue,
        from: pageNumber * pageSize,
        sort: sortColumn,
        ...(title === "Revenue Estimate" && {
          externalPropertyID: variables.externalPropertyID,
        }),
        ...(tableType === "registration_history" && {
          permitRegistrationID: variables.permitRegistrationID,
        }),
      },
    });
    const params = {
      ...queryParams,
      filters: selectedFilters || undefined,
      q: searchValue || undefined,
      operator: selectedFilters.length < 2 ? undefined : operator,
      size: pageSize !== DEFAULT_PAGE_SIZE ? pageSize : undefined,
      page: pageNumber || undefined,
      sort: sortColumn || undefined,
      preDefinedFilter: preDefinedFilter || undefined,
      selectedView: selectedView || undefined,
    };

    history.push({
      search: qs.stringify(params),
    });
  }, reRenderTriggers);

  useEffect(() => {
    handleClearSelection();
    if (storedColumns.length) {
      const localData: string | null = localStorage.getItem(location.pathname);
      let pinnedColumns = {};
      if (localData) {
        pinnedColumns = JSON.parse(localData)["pinnedColumns"];
      }
      localStorage.removeItem(location.pathname.replace(/\/$/, ""));
      localStorage.setItem(
        location.pathname.replace(/\/$/, ""),
        JSON.stringify({
          columnsDefinition: storedColumns.map((column) => {
            if (column.field == "external_property_id") {
              column.headerName = "Rental Unit";
            }
            return (({ field, headerName, width, hide }) => ({
              field,
              headerName,
              width,
              hide,
            }))(column);
          }),
          pinnedColumns: pinnedColumns,
        })
      );
    }
  }, [storedColumns]);

  const handleFilterModelChange = (model: GridFilterModel) => {
    handleClearSelection();
    let newFilters: GridFilterItem[] = [];

    model.items.forEach((filter: GridFilterItem) => {
      if (Array.isArray(filter.value)) {
        if (filter.value?.length && !filter.value.includes("No Options")) {
          newFilters.push({
            columnField: filter.columnField,
            value: filter.value,
            operatorValue: filter.operatorValue,
          });
        }
      } else {
        // if filter.value is an object, for instance filter.value: {fromDate: "2024-04-05T00:00:00+05:30", toDate: "2024-04-06T00:00:00+05:30"}
        newFilters.push({
          columnField: filter.columnField,
          value: filter.value,
          operatorValue: filter.operatorValue,
        });
      }
    });

    // Update column visibility based on applied filters
    const appliedColumns = newFilters.map((filter) => filter.columnField);
    updateVisibleColumns(appliedColumns);

    if (model.linkOperator && newFilters.length > 1) {
      setOperator(model.linkOperator.toUpperCase());
      setPageNumber(0);
    }

    if (newFilters.length === 0 && selectedFilters.length !== 0) {
      setSelectedFilters([]);
    }

    if (
      newFilters.length !== selectedFilters.length ||
      JSON.stringify(newFilters) !== JSON.stringify(selectedFilters)
    ) {
      setPreDefinedFilter("");
      setSelectedView("");
      setSelectedFilters(newFilters);
      setPageNumber(0);
    }
  };

  const handleSortModelChange = (model: GridSortModel) => {
    handleClearSelection();
    if (model.length === 1) {
      const sortDirection = model[0].sort === "asc" ? "" : "-";
      setSortColumn(`${sortDirection}${model[0].field}`);
    } else {
      setSortColumn("");
    }
    setPageNumber(0);
  };

  const prepareInitialFilters = (): GridFilterModel => {
    return {
      items: selectedFilters,
      linkOperator:
        queryParams.operator?.toString().toLowerCase() === "and"
          ? GridLinkOperator.And
          : GridLinkOperator.Or,
    };
  };

  const prepareInitialSorting = (): GridSortModel => {
    if (sortColumn === "") {
      return [];
    }

    const direction: GridSortDirection = sortColumn.startsWith("-")
      ? "desc"
      : "asc";
    return [
      {
        field: direction === "desc" ? sortColumn.substring(1) : sortColumn,
        sort: direction,
      },
    ];
  };

  const pluckValuesForKey = (rowData: any, key: string) => {
    return rowData.map((o: { [x: string]: any }) => {
      if (HTMLColumns.html.includes(key)) {
        const content = o[key];
        return extractContent(content);
      }
      return o[key];
    });
  };

  const longestValueInCell = (values: any[]) => {
    return values
      .filter((value) => value !== null && value !== undefined)
      .reduce(
        (a, b) =>
          a.toString().length > b.toString().length
            ? a.toString()
            : b.toString(),
        ""
      );
  };

  const getWidthFortheColumn = (fieldName: string, length: number): number => {
    switch (fieldName) {
      case "listings":
        return 275; // fixed length

      case "property_page":
        return 180; // fixed length

      case "property_pages":
        return 180; // fixed length

      case "external_property_id":
        return 95; // fixed length

      default:
        return length * 10;
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleClearSelection();
    const newValue = event.target.value;
    setSearchInputValue(newValue);

    if (newValue.length >= 3 || newValue.length === 0) {
      setPreDefinedFilter("");
      setSelectedView("");
      setSearchValue(event.target.value);
      setPageNumber(0);
    }
  };

  let requestedData = false;
  if (data && data.results) {
    requestedData = true;
    dataToShow = data.results.hits.map((hit: any) => {
      const extract_extra = { ...hit, ...hit.extra };
      delete extract_extra["extra"];
      return extract_extra;
    });
    if (
      dataToShow.length > 0 &&
      (!initialData ||
        Object.keys(dataToShow[0]).length > Object.keys(initialData).length)
    ) {
      setInitialData(dataToShow[0]);
    }
    rowCount = data.results.total;
  }

  const isBooleanField = (field: string) => {
    const column: any = storedColumns?.filter((o) => o.field === field);
    return column.length > 0
      ? column[0].fieldType === "boolean" || column[0].fieldType === "bool"
      : false;
  };

  const handleEditableFieldUpdate = async (
    id: string,
    field: string,
    selectedOption: EditableFieldOption
  ) => {
    let fieldName = field.replaceAll("_", " ");
    const table = tableType;
    const successMessage =
      fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    try {
      await createOrUpdateStatus({
        variables: {
          id,
          table,
          field,
          value: selectedOption.id,
        },
      });
    } catch (e: unknown) {
      let message = "";
      if (typeof e === "string") {
        message = e.toUpperCase();
      } else if (e instanceof Error) {
        message = e.message;
      }
      setSnackbarData({
        message,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return { type: "error" };
    }
    setSnackbarData({
      message: `${successMessage} updated successfully.`,
      type: "success",
      vertical: "top",
      horizontal: "center",
    });
    // setTrigger(!trigger);
    return { type: "success" };
  };

  const handleViewDeletion = async (viewKey: string) => {
    handleClearSelection();
    try {
      await deleteView({
        variables: {
          key: viewKey,
        },
      });
    } catch (e: unknown) {
      let message = "";
      if (typeof e === "string") {
        message = e.toUpperCase();
      } else if (e instanceof Error) {
        message = e.message;
      }
      setSnackbarData({
        message,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return { type: "error", message: message }; // need this in child component to control dropdown collpasing
    }
    setSnackbarData({
      message: "View deleted successfully.",
      type: "success",
      vertical: "top",
      horizontal: "center",
    });
    return { type: "success" };
  };

  const handleViewCreation = async (newView: string) => {
    handleClearSelection();

    const viewType = titleCase(title);
    const filters = selectedFilters || undefined;
    const linkOperator = operator.toLowerCase() || GridLinkOperator.Or;
    const columns = storedColumns || undefined;
    const sortBy = sortColumn || undefined;
    const preDefinedFilterValue = preDefinedFilter || undefined;
    const createdAt = new Date().toUTCString();

    const value: ViewValue = {
      name: newView,
      preDefinedFilter: preDefinedFilterValue,
      filters,
      linkOperator,
      columns,
      sortBy,
      createdAt,
    };

    try {
      // Check for existing views with the same name
      const matched = views?.some((item: TableView) => {
        const viewJSON: ViewValue = JSON.parse(item.value);
        return newView.toLowerCase() === viewJSON?.name?.toLowerCase();
      });

      if (matched) {
        throw new Error(
          "View name already exists. Please enter a different name and try again."
        );
      }

      // Create or edit the view
      await createOrEditView({
        variables: {
          key: `com.hostcompliance.react.filters.${viewType}.${crypto.randomUUID()}`,
          value: JSON.stringify(value),
          overwrite: false,
        },
      });

      return { type: "success" };
    } catch (e) {
      let message = e instanceof Error ? e.message : String(e);

      if (message.includes("Cannot overwrite key")) {
        message =
          "View name already exists. Please enter a different name and try again.";
      }

      setSnackbarData({
        message,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });

      return { type: "error", message: message }; // added this to control dropdown collpasing in child component
    }
  };

  const handleViewSelection = async (viewKey: string): Promise<void> => {
    handleClearSelection();
    setPreDefinedFilter("");

    const selectedViewData = views?.find(
      (item: TableView) => item.key === viewKey
    );
    if (!selectedViewData) return;

    const parsedValue: ViewValue = JSON.parse(selectedViewData.value);

    setSelectedView(selectedViewData.key);

    if (parsedValue.columns && parsedValue.columns.length > 0) {
      setStoredColumns(parsedValue.columns);
    }

    if (parsedValue.filters && parsedValue.filters.length > 0) {
      setSelectedFilters(parsedValue.filters);
      setOperator(
        (parsedValue.linkOperator || GridLinkOperator.Or).toUpperCase()
      );

      const filterItems = parsedValue.filters.map(
        (filterItem: GridFilterItem, index: number) => ({
          id: index,
          columnField: filterItem.columnField,
          value: filterItem.value,
          operatorValue: filterItem.operatorValue,
        })
      );

      gridRef.current.setFilterModel({
        items: filterItems,
        linkOperator: (parsedValue.linkOperator ||
          GridLinkOperator.Or) as GridLinkOperator,
      });
    }

    if (parsedValue.sortBy) {
      const direction: GridSortDirection = parsedValue.sortBy.startsWith("-")
        ? "desc"
        : "asc";
      const sortField =
        direction === "desc"
          ? parsedValue.sortBy.substring(1)
          : parsedValue.sortBy;

      setSortColumn(parsedValue.sortBy);
      gridRef.current.setSortModel([{ field: sortField, sort: direction }]);
    }

    const params = {
      filters: parsedValue.filters || undefined,
      sort: parsedValue.sortBy || undefined,
      preDefinedFilter: parsedValue.preDefinedFilter || undefined,
      selectedView: selectedView || undefined,
      operator:
        parsedValue.filters && parsedValue.filters.length < 2
          ? undefined
          : (parsedValue.linkOperator || GridLinkOperator.Or).toUpperCase(),
    };

    history.push({ search: qs.stringify(params) });
  };

  const handleSetPredefinedFilter = (newFilter: string): void => {
    handleClearSelection();
    let columnsToMakeVisible: string[] = []; // Track columns of selected filter to be made visible

    if (newFilter === "All") {
      gridRef.current.setFilterModel({
        items: [],
        linkOperator: GridLinkOperator.Or,
      });
      handleFilterModelChange(gridRef.current.state.filter.filterModel);
    } else {
      const preDefinedFilter: TableMetaFilters | undefined = filters.find(
        (filter) => filter.title === newFilter
      );
      let items: GridFilterItem[] = [];

      if (preDefinedFilter && preDefinedFilter.rules) {
        items = preDefinedFilter.rules
          .filter((rule: TableMetaFilter) => rule.options !== null)
          .map((rule, index) => {
            // Collect the column fields that need to be visible
            columnsToMakeVisible.push(rule.source);

            if (isBooleanField(rule.source)) {
              let ruleOptions = rule.options.map((option: any) => {
                if (option === "True" || option === "true") {
                  return "true";
                } else if (option === "False" || option === "false") {
                  return "false";
                }
              });
              return {
                id: index,
                columnField: rule.source,
                value: ruleOptions,
                operatorValue: "isAnyOf",
              };
            }
            return {
              id: index,
              columnField: rule.source,
              value: rule.options || [],
              operatorValue: "isAnyOf",
            };
          });
      }

      updateVisibleColumns(columnsToMakeVisible);
      setSearchValue("");
      setPageNumber(0);
      gridRef.current.setFilterModel({
        items: items,
      });

      const selectedFilterModel = gridRef.current.state.filter.filterModel;

      if (AND_FILTERS.includes(newFilter)) {
        selectedFilterModel.linkOperator = GridLinkOperator.And;
      } else {
        selectedFilterModel.linkOperator = GridLinkOperator.Or;
      }

      handleFilterModelChange(selectedFilterModel);
      setPreDefinedFilter(newFilter);
    }
  };

  const updateVisibleColumns = (columns: string[]) => {
    const updatedColumns = storedColumns.map((storedColumn) => {
      if (columns.includes(storedColumn.field)) {
        return { ...storedColumn, hide: false };
      }
      return storedColumn;
    });

    setStoredColumns(updatedColumns);
  };

  /**
   * Parse title to bucket
   * @param title
   * @returns
   */
  const parseTitleToBucket = (title: string) => {
    switch (title.toLowerCase()) {
      case "registrations":
        return "PERMITS";
      case "rental units":
        return "RENTAL_UNITS";
      case "listings":
        return "LISTINGS";
      case "letters":
        return "LETTERS";
      case "audit discovery":
        return "AUDIT_DISCOVERY";
      case "audit management":
        return "AUDIT_MANAGEMENT";
      case "str hotline":
        return "STR_HOTLINE";
      case "reported revenue":
        return "REPORTED_REVENUE";
      case "invoices":
        return "INVOICES";
      case "payments":
        return "PAYMENTS";
      case "revenue estimate":
        return "REVENUE_ESTIMATES";
      case "emails":
        return "EMAILS";
      default:
        return null;
    }
  };

  const handleGenerateExport = () => {
    handleClearSelection();
    generateExport({
      variables: {
        item: {
          type: "SEARCH",
          bucket: parseTitleToBucket(title),
          search: {
            query: query,
            from: 0,
            sort: sortColumn,
            phrase: searchValue,
          },
          columns: getVisibleColumnsForExport(),
        },
        ...(title === "Revenue Estimate" && {
          only: {
            external_property_id: [variables.externalPropertyID],
          },
        }),
      },
    })
      .then(() =>
        setSnackbarData({
          message: "Link to your export will be sent to your email.",
          type: "success",
        })
      )
      .catch(() =>
        setSnackbarData({
          message: "An error occurred, please try again later.",
          type: "error",
        })
      );
  };

  useEffect(() => {
    if (exportLoading) {
      setSnackbarData({ message: "Generating export...", type: "info" });
    }
  });

  const handleColumnVisibilityModelChange = (
    params: GridColumnVisibilityModel
  ) => {
    let somethingChanged = false;
    const updatedStoredColumns: GridColDef[] = storedColumns.map((column) => {
      if (!(column.hide === !params[column.field])) {
        column.hide = !params[column.field];
        somethingChanged = true;
      }
      return column;
    });

    if (somethingChanged) {
      const localData = localStorage.getItem(location.pathname);
      let localColumnsDefinition = updatedStoredColumns;

      if (localData) {
        const initialStoredColumns = JSON.parse(localData).columnsDefinition;
        localColumnsDefinition = getOrderdColumns(
          updatedStoredColumns,
          initialStoredColumns,
          false
        );
      }

      // Everytime before setting stored columns , we need to get the order from localstorage like above -  this needs a better approach
      setStoredColumns(localColumnsDefinition);
    }
  };

  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    const updatedStoredColumns = storedColumns.map((column) => {
      if (column.field === params.colDef.field) {
        return { ...column, width: params.colDef.width };
      }
      return column;
    });

    const localData = localStorage.getItem(location.pathname);
    let localColumnsDefinition = updatedStoredColumns;

    if (localData) {
      const initialStoredColumns = JSON.parse(localData).columnsDefinition;

      localColumnsDefinition = getOrderdColumns(
        updatedStoredColumns,
        initialStoredColumns,
        false
      );
    }
    // Everytime before setting stored columns , we need to get the order from localstorage like above -  this needs a better approach
    setStoredColumns(localColumnsDefinition);
  };

  const updateLocalColumnsDefinitionOrder = (columns: GridColDef[]) => {
    const localData: string | null = localStorage.getItem(location.pathname);
    let pinnedColumns = {};
    if (localData) {
      pinnedColumns = JSON.parse(localData)["pinnedColumns"];
    }
    localStorage.removeItem(location.pathname.replace(/\/$/, ""));
    localStorage.setItem(
      location.pathname.replace(/\/$/, ""),
      JSON.stringify({
        columnsDefinition: columns.map((column) => {
          if (column.field == "external_property_id") {
            column.headerName = "Rental Unit";
          }
          return (({ field, headerName, width, hide }) => ({
            field,
            headerName,
            width,
            hide,
          }))(column);
        }),
        pinnedColumns: pinnedColumns,
      })
    );
  };

  const handleColumnOrderChange = () => {
    if (gridRef.current) {
      updateLocalColumnsDefinitionOrder(gridRef.current.getAllColumns());
    }
  };

  const updatePinnedColumns = (pinnedColumns: GridPinnedColumns) => {
    setPinnedColumns(pinnedColumns);

    const localData: string | null = localStorage.getItem(location.pathname);
    let initialStoredColumns: GridColDef[] = [];

    if (localData) {
      initialStoredColumns = JSON.parse(localData)["columnsDefinition"];
    }
    localStorage.removeItem(location.pathname.replace(/\/$/, ""));
    localStorage.setItem(
      location.pathname.replace(/\/$/, ""),
      JSON.stringify({
        columnsDefinition: initialStoredColumns,
        pinnedColumns: pinnedColumns,
      })
    );
  };

  const getVisibleColumnsForExport = () => {
    const visibleColumns = gridRef.current.getVisibleColumns();
    return visibleColumns.map((col) => ({
      field: col.field,
      label: col.headerName,
    }));
  };

  const getVisibleColumnsOfDataGrid = () => {
    const visibleColumnObjects = gridRef.current.getVisibleColumns();
    const visibleColumns = visibleColumnObjects.map(
      (columnObj) => columnObj.field
    );
    return visibleColumns.filter((item) => item !== "__check__");
  };

  const getFromRows = () => {
    if (data && data.results) {
      const fromRows = pageNumber * pageSize;
      return data.results.total === 0 ? 0 : fromRows + 1;
    }
    return 0;
  };

  const getToRows = () => {
    if (data && data.results) {
      const fromRows = pageNumber * pageSize;
      return data.results.size < data.results.total
        ? data.results.size + fromRows
        : data.results.total;
    }
    return 0;
  };

  const handleClearSelection = () => {
    setSelectionModel([]);
    setShowCheckboxes(false);
    setShowRentalUnitSelectionSection(false);
  };

  const handleViewTemplateButtonClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowCheckBoxes = () => {
    setShowCheckboxes(true);
    setShowRentalUnitSelectionSection(true);
    resetSelectionSection();
  };

  const handleColumnVisibilityChange = (params: any) => {
    if (params.field === "__check__" && !params.visibility) {
      handleClearSelection();
    }
  };

  const handleTemplateClick = (selectedTemplate: any) => {
    if (!eligibilityQueryLoading) {
      if (selectionModel.length === 0) {
        setSnackbarData({
          message: "Please select one or more properties.",
          type: "info",
          vertical: "top",
          horizontal: "center",
        });
        handleClose();
      } else {
        setSelectedTemplate(selectedTemplate);
        fetchBulkEligibilityTask(selectedTemplate);
      }
    }
  };

  const fetchBulkEligibilityTask = (selectedTemplate: any) => {
    setEligibilityQueryLoading(true);
    const filterQuery = prepareFiltersToQuery();

    const eligibilityQueryVars = {
      rental_unit_ids: selectionModel,
      all: false,
      template_id: selectedTemplate.letter_template_id,
      search: {
        // needed to check eligibility for filtered RURs
        query: filterQuery,
        phrase: searchValue,
        from: 0,
        size: rowCount,
        sort: "",
      },
      search_fields: getVisibleColumnsOfDataGrid(), // needed to check eligibility for filtered RURs when search phrase is used
    };

    if (isAllRentalUnitsSelected) {
      eligibilityQueryVars.rental_unit_ids = [];

      if (filterQuery === "" && searchValue === "") {
        eligibilityQueryVars.all = true; //all needs to be true only for ALL unfiltered RUR selection
      }
    }

    refetechEligibilityQuery(eligibilityQueryVars)
      .then((response: any) => {
        setEligibilityData(response?.data?.bulk_letters_criteria_task);

        switch (response?.data?.bulk_letters_criteria_task?.__typename) {
          case "BulkLetterCriteriaResponse":
            processBulkLetterCriteriaResponse(
              response.data.bulk_letters_criteria_task,
              selectedTemplate
            );
            break;

          case "TaskResult":
            checkTaskStatus(
              response.data.bulk_letters_criteria_task.id,
              response.data.bulk_letters_criteria_task.name,
              selectedTemplate
            );
            break;

          default:
            break;
        }
      })
      .catch((error: ApolloError) => {
        setSnackbarData({
          message: error.message,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        setEligibilityQueryLoading(false);
        handleClose();
      });
  };

  const handleNavigation = (updatedResponse: any) => {
    history.push({
      pathname: "/dashboard/rental-units/sendLetters",
      state: updatedResponse,
    });
  };

  const handleSelectionModelChange = (newSelection: any) => {
    setSelectionModel(newSelection);
  };

  const { refetch: taskBulkLettersCriteriaResultQueryRefetch } = useQuery(
    taskBulkLettersCriteriaResultQuery,
    {
      variables: {
        task_id: eligibilityData?.bulk_letters_criteria_task?.id,
        task_name: eligibilityData?.bulk_letters_criteria_task?.name,
      },
      skip: true,
      onError: (error: ApolloError) => {
        setSelectedTemplate({});
        setSnackbarData({
          message: error.message,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        handleClose();
      },
    }
  );

  const fetchTaskBulkLettersCriteriaResult = (
    taskID: string,
    taskName: string,
    selectedTemplate: any
  ) => {
    taskBulkLettersCriteriaResultQueryRefetch({
      task_id: taskID,
      task_name: taskName,
    })
      .then((response: any) => {
        processBulkLetterCriteriaResponse(
          response.data?.task_bulk_letters_criteria_result,
          selectedTemplate
        );
      })
      .catch((error: ApolloError) => {
        setSnackbarData({
          message: error.message,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        setEligibilityQueryLoading(false);
        handleClose();
      });
  };

  const { refetch: taskDetailsQueryRefetch } = useQuery(taskDetailsQuery, {
    variables: { id: eligibilityData?.bulk_letters_criteria_task?.id },
    skip: true,
    onError: (error: ApolloError) => {
      setSelectedTemplate({});
      setSnackbarData({
        message: error.message,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      handleClose();
    },
  });

  const checkTaskStatus = (
    taskID: string,
    taskName: string,
    selectedTemplate: any
  ) => {
    taskDetailsQueryRefetch({ id: taskID })
      .then((response: any) => {
        if (response.data.task_details.status !== true) {
          setTimeout(() => {
            checkTaskStatus(taskID, taskName, selectedTemplate);
          }, 2000);
        } else {
          fetchTaskBulkLettersCriteriaResult(
            taskID,
            taskName,
            selectedTemplate
          );
        }
      })
      .catch((error: ApolloError) => {
        setSnackbarData({
          message: error.message,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        setEligibilityQueryLoading(false);
        handleClose();
      });
  };

  const processBulkLetterCriteriaResponse = (
    bulkLetterCriteriaResponse: any,
    selectedTemplate: any
  ) => {
    const updatedResponse = {
      ...bulkLetterCriteriaResponse,
      selectedTemplate: selectedTemplate,
      recepient_info: bulkLetterCriteriaResponse.recepient_info.map(
        (recipient: any) => ({
          ...recipient,
          id: recipient.rental_unit_record, // Adding the "id" field with the same value as "rental_unit_record" so that data grid have a unique identifier for each row in the eligible recipients grid
        })
      ),
      non_eligible_details: bulkLetterCriteriaResponse.non_eligible_details.map(
        (ineligibleRecipient: any) => ({
          ...ineligibleRecipient,
          id: ineligibleRecipient.rental_unit_record, // Adding the "id" field with the same value as "rental_unit_record" so that data grid have a unique identifier for each row in the ineligible recipients grid
        })
      ),
    };

    setEligibilityQueryLoading(false);
    handleClose();
    handleNavigation(updatedResponse);
  };

  const selectAllRentalUnitIDs = () => {
    const rentalUnitIDs: any[] = [];
    data.results.hits.forEach((hit: any) => {
      rentalUnitIDs.push(hit.id);
    });
    setSelectionModel(rentalUnitIDs);
  };

  const deSelectAllRentalUnitIDs = () => {
    setSelectionModel([]);
  };

  const selectAllRentalUnitsClickHandler = () => {
    setIsAllRentalUnitsSelected(true);
    selectAllRentalUnitIDs();
    setShowClearRentalUnitsSelectionCTA(true);
    setShowSelectAllRentalUnitsCTA(false);
    setShowAllRentalUnitsSelectedText(true);
    setShowRentalUnitsSelectedText(false);
  };

  const clearAllRentalUnitsSelectionClickHandler = () => {
    setIsAllRentalUnitsSelected(false);
    deSelectAllRentalUnitIDs();
  };

  const resetSelectionSection = () => {
    setIsAllRentalUnitsSelected(false);
    setShowClearRentalUnitsSelectionCTA(false);
    setShowSelectAllRentalUnitsCTA(true);
    setShowAllRentalUnitsSelectedText(false);
    setShowRentalUnitsSelectedText(true);
  };

  return (
    <div style={{ height: "calc(100% - 74px)" }}>
      {(eligibilityQueryLoading || loadingForReportIssue) && (
        <div className="fullScreenOverlay">
          <LoadingScreen />
        </div>
      )}

      <SearchViewToolbar
        searchValue={searchInputValue}
        handleSearch={handleSearch}
        title={title}
        data={data}
        fromRows={getFromRows()}
        toRows={getToRows()}
        readOnly={storedColumns.length > 0 && loading}
        showCheckboxes={showCheckboxes}
        handleShowCheckBoxes={handleShowCheckBoxes}
        handleClearSelection={handleClearSelection}
        handleViewTemplateButtonClick={handleViewTemplateButtonClick}
        openTemplatesMenu={openTemplatesMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
        templates={letterTemplates?.letter_templates[0]?.templates}
        handleTemplateClick={handleTemplateClick}
        loading={eligibilityQueryLoading}
        selectedTemplate={selectedTemplate}
      />
      {showRentalUnitSelectionSection && (
        <>
          <div className="selection-options-container">
            {showRentalUnitsSelectedText && (
              <p className="selection-section-text font-weight-300">
                <span className="font-weight-500">{selectionModel.length}</span>{" "}
                Rental Unit
                {selectionModel.length !== 1 ? "s" : ""} Selected
              </p>
            )}
            {showSelectAllRentalUnitsCTA && (
              <a
                className="selection-section-cta"
                onClick={selectAllRentalUnitsClickHandler}
              >
                <span className="font-weight-500">
                  Select all {rowCount} Rental Units
                </span>
              </a>
            )}
            {showAllRentalUnitsSelectedText && (
              <p className="selection-section-text font-weight-300">
                All <span className="font-weight-500">{rowCount}</span> Rental
                Units Selected
              </p>
            )}
            {showClearRentalUnitsSelectionCTA && (
              <a
                className="selection-section-cta"
                onClick={clearAllRentalUnitsSelectionClickHandler}
              >
                <span className="font-weight-500">Clear Selection</span>
              </a>
            )}
          </div>
        </>
      )}
      {title === "Revenue Estimate" &&
        storedColumns.length > 0 &&
        dataToShow.length > 0 && (
          <div className="banner-container">
            <p>
              Revenue Estimate for{" "}
              <a
                href={"/dashboard/rental-units/" + variables.externalPropertyID}
                target="_blank"
                rel="noreferrer"
                id="externalPropertyLink"
              >
                {variables.externalPropertyID}
              </a>{" "}
              ({dataToShow[0].address})
            </p>
          </div>
        )}
      <Snackbar
        open={!!snackbarData.message}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: snackbarData.vertical || "top",
          horizontal: snackbarData.horizontal || "right",
        }}
        onClose={() => setSnackbarData({ message: "", type: "success" })}
      >
        <Alert
          severity={snackbarData.type}
          sx={{ width: "100%" }}
          onClose={() => setSnackbarData({ message: "", type: "success" })}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>
      {error && <Alert severity="error">Error! {error.message}</Alert>}
      <Paper
        sx={{
          width: "calc(100% - 64px)",
          overflow: "hidden",
          margin: "0 32px",
          height: "calc(100% - 84px)",
        }}
      >
        {storedColumns.length > 0 && loading ? (
          <div className="overLay"></div>
        ) : undefined}
        {storedColumns.length ? (
          <DataGridPro
            density={density}
            onStateChange={handleStateChange}
            className={`${title.split(" ").join("")}View dark-row-hover`} // Using this to set view specific css changes .
            apiRef={gridRef}
            loading={loading}
            columns={storedColumns}
            onPinnedColumnsChange={updatePinnedColumns}
            pinnedColumns={pinnedColumns}
            checkboxSelection={showCheckboxes}
            disableSelectionOnClick={!showCheckboxes}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelection) => {
              handleSelectionModelChange(newSelection);
            }}
            onColumnWidthChange={handleColumnWidthChange}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            components={{
              Toolbar: DataGridCustomToolbar,
              FilterPanel: GridToolBarFilter,
            }}
            componentsProps={{
              toolbar: {
                filters: filters,
                views: views,
                setViewsCallBack: handleFetchViews,
                selectedFilters: selectedFilters,
                onPreDefinedFiltersChange: (newFilter: string) =>
                  handleSetPredefinedFilter(newFilter),
                onViewSelect: (viewName: string) =>
                  handleViewSelection(viewName),
                createView: (viewName: string) => handleViewCreation(viewName),
                deleteView: (viewName: string) => handleViewDeletion(viewName),
                preDefinedFilter: preDefinedFilter,
                selectedView: selectedView,
                generateExport: handleGenerateExport,
                showExport: parseTitleToBucket(title),
                clearFilter: () => {
                  gridRef.current.setFilterModel({
                    items: [],
                    linkOperator: GridLinkOperator.Or,
                  });
                  handleFilterModelChange(
                    gridRef.current.state.filter.filterModel
                  );
                },
              },
              filterPanel: {
                columnsSort: "asc",
                applyFilter: () => {
                  gridRef.current.hideFilterPanel();
                  handleFilterModelChange(
                    gridRef.current.state.filter.filterModel
                  );
                },
              },
            }}
            initialState={{
              filter: {
                filterModel: prepareInitialFilters(),
              },
              sorting: {
                sortModel: prepareInitialSorting(),
              },
            }}
            filterMode="server"
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            pagination
            paginationMode={"server"}
            rowsPerPageOptions={[20, 50, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              setPageNumber(0);
            }}
            page={pageNumber}
            onPageChange={(newPage) => setPageNumber(newPage)}
            rows={dataToShow}
            rowCount={rowCount}
          />
        ) : (
          <LoadingScreen />
        )}
      </Paper>
    </div>
  );
}

export default SearchView;
