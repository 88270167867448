import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Typography,
} from "@mui/material";
import parser from "./parser";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const methodsAllHaveSamePrice = (payment_methods) => {
  return !payment_methods?.filter(function (o) {
    return o.amount_due !== payment_methods[0].amount_due;
  }).length;
};

function View({ question, context }) {
  const [interval, setInterval] = useState([]);
  const [expanded, setExpanded] = useState({});
  const input = parser.parse(question.input, context); // Input data
  const paymentMethods = context.pick("payment_methods");
  const sameAmountDue = methodsAllHaveSamePrice(paymentMethods);
  const payments = sameAmountDue ? paymentMethods?.slice(0, 1) : paymentMethods;

  useEffect(() => {
    const updatedIntervals = context.pick("interval")?.map((item) => {
      let total = 0;
      input.itemization.options.forEach((foo) => {
        item[foo.key] = parseFloat(item[foo.key]);
        if (isNaN(item[foo.key])) {
          item[foo.key] = 0;
        }
        total += item[foo.key];
      });
      return { ...item, total };
    });
    setInterval(updatedIntervals);
  }, []);

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div>
      <List>
        {payments?.map((payment, index) => (
          <div key={index}>
            <ListItem style={{ display: "block" }}>
              {(sameAmountDue ? paymentMethods : [payment]).map(
                (method, index) => (
                  <Typography variant="h6" key={index}>
                    {method.name === "ach" && <strong>Bank Account</strong>}
                    {method.name === "card" && (
                      <strong>Credit or Debit Card</strong>
                    )}
                    {method.name === "cash" && <strong>Cash</strong>}
                    {method.name === "check" && <strong>Check</strong>}
                  </Typography>
                )
              )}
              <Typography className="subtext">
                {sameAmountDue ? "Payment Methods" : "Payment Method"}
              </Typography>
              <ListItemSecondaryAction>
                <Typography variant="h6" component="strong">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(payment.amount_due)}
                </Typography>
                <IconButton edge="end" onClick={() => toggleExpand(index)}>
                  {expanded[index] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {expanded[index] &&
              payment.itemization.map((item, itemIndex) => (
                <ListItem key={itemIndex}>
                  <ListItemText primary={item.description} />
                  <ListItemSecondaryAction className="secondary-item">
                    <Typography component="p" className="secondary-item-text">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(item.amount)}
                    </Typography>
                    <div></div>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            <Divider />
          </div>
        ))}
      </List>
      {interval &&
        interval.length > 0 &&
        interval.map((period, periodIndex) => (
          <div key={periodIndex} style={{ display: "block" }}>
            <List className="md-xdense">
              <ListItem className="head">
                <Typography variant="body2" className="text-color">
                  {period.description}
                </Typography>
              </ListItem>
            </List>
          </div>
        ))}
    </div>
  );
}

export default View;
