import "./questionnaires.css";

import React, { useEffect, useState } from "react";
import { withApollo } from "@apollo/client/react/hoc";

import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import env from "../common/env";
import { featureFlags } from "../common/feature-flags";
import {
  QuestionnairesContext,
  QuestionnairesProvider,
} from "../common/questionnaires/provider";
import { queryStringToObject } from "../dashboard/common/utils";
import { useLocation, useHistory } from "react-router-dom";
import hooks from "../common/questionnaires/hooks";
import { setData, getData } from "../common/questionnaires/util";

const GET_QJSON = `
  query ($mode: Mode, $questionnaireId: ID!, $jurisdictionId: ID) {
    questionnaire(
      id: $questionnaireId
      jurisdiction_id: $jurisdictionId
      mode: $mode
    ) {
      properties
      hooks
      questions
      version
    }
  }
`;

//
function View({ match }) {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const { user, logout } = useAuth0();
  let userFeatures = featureFlags.get();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryString = queryStringToObject(searchParams);
  const history = useHistory();
  const [context, setContext] = useState({});
  const prefix = `com.hostcompliance.questionnaire${
    match.params.jurisdictionId ? "." + match.params.jurisdictionId : ""
  }.${match.params.questionnaireId}`;
  const previousQuestionnaireId = getData("questionnaireID");
  const prefixToSession = `com.hostcompliance.questionnaire${
    match.params.jurisdictionId ? "." + match.params.jurisdictionId : ""
  }.${previousQuestionnaireId}`;
  const contextInstance = new QuestionnairesContext(prefixToSession, [
    "session",
    "params",
  ]);
  const client = useApolloClient();
  const isWelcomePage = match.params.questionId === "welcome";
  const initialQuestionnaireData = isWelcomePage ? null : getData(prefix);
  const [questionnaireData, setQuestionnaireData] = useState(
    initialQuestionnaireData
  );

  useEffect(() => {
    if (match.params.questionId === "welcome") {
      sessionStorage.clear();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (questionnaireData === null) {
          const res = await hooks.fetchData(env.API_URL, {
            query: GET_QJSON,
            variables: {
              ...match.params,
              mode: userFeatures.questionnnaires_test ? "TEST" : "LIVE",
            },
          });

          if (res) {
            setData(prefix, {
              ...res,
            });
            setQuestionnaireData(res);
          }
        }
      } catch (error) {
        console.error("Error fetching questionnaire data:", error);
      }
    };

    fetchData();
  }, [questionnaireData, match.params, userFeatures.questionnnaires_test]);

  useEffect(() => {
    if (!questionnaireData) return;
    async function getToken() {
      if (
        !match.params.questionId ||
        questionnaireData.data.questionnaire.questions[
          match.params.questionId
        ] === undefined
      ) {
        let currentLocation = location.pathname;
        const initialQuestionId =
          questionnaireData.data.questionnaire.properties.initial;

        currentLocation = match.params.questionId
          ? currentLocation.replace("registration_type", initialQuestionId)
          : `${currentLocation}/${initialQuestionId}`;

        match.params.questionId = initialQuestionId;
        history.replace(currentLocation, match.params);
      }

      let params = { ...match.params, ...queryString };
      if (contextInstance.master.params?.invoiceId) {
        params.invoiceId = contextInstance.master.params.invoiceId;
      }
      if (isAuthenticated && contextInstance.master.params) {
        const { route } = contextInstance.master.params;
        if (route && !params.route) {
          params.route = route;
        }
      }
      const token = isAuthenticated ? await getAccessTokenSilently() : null;
      const decodedToken = token ? jwt_decode(token) : {};
      const context = {
        checksum: questionnaireData.data.questionnaire.properties.checksum,
        config: {
          API_URL: env.API_URL,
          GOOGLE_MAPS_API_KEY: env.GOOGLE_MAPS_API_KEY,
          API_URL_V1: env.API_URL_V1,
        },
        params: { ...params, customerId: match.params.jurisdictionId },
        session: {
          token_type: "Bearer",
          access_token: token,
          access_token_decoded: decodedToken,
          metadata: decodedToken["https://api.hostcompliance.com/metadata"],
          scope: decodedToken.scope,
        },
        admin: decodedToken.iss === "https://hostcompliance.auth0.com/",
        questionnaireId: match.params.questionnaireId,
      };

      if (
        !isAuthenticated &&
        !["welcome", "signin"].includes(match.params.questionId) &&
        contextInstance.master.session
      ) {
        let tempSession = { ...contextInstance.master.session };
        if (tempSession.access_token) {
          const decodedAccessToken = jwt_decode(tempSession.access_token);
          tempSession.access_token_decoded = {
            ...decodedAccessToken,
            payload: {
              metadata:
                decodedAccessToken["https://api.hostcompliance.com/metadata"],
            },
          };
        }
        context.session = tempSession;
        context.admin = false;
      }
      setData("questionnaireID", match.params.questionnaireId);
      setContext(context);
    }
    if (!isLoading && questionnaireData.data) {
      getToken();
    }
  }, [questionnaireData, isLoading]);

  const toggleFeature = (key) => {
    userFeatures[key] = !userFeatures[key];
    featureFlags.set(userFeatures);
    window.location.reload();
  };

  const signout = function () {
    client.resetStore();
    logout({ returnTo: window.location.origin });
  };

  const [sessionMenuEl, setSessionMenuEl] = useState(null);
  const sessionMenuOpen = Boolean(sessionMenuEl);

  const handleClick = (event) => {
    setSessionMenuEl(
      event.currentTarget.id === "session-button" ? event.currentTarget : null
    );
  };

  const goBack = () => {
    history.goBack();
  };

  if (isLoading || !questionnaireData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="questionnaires">
      {!isLoading && questionnaireData && context.config && (
        <div>
          {userFeatures.qjson_editor && (
            <AppBar
              position="static"
              color="default"
              style={{ boxShadow: "none", backgroundColor: "#c3c3c3" }}
            >
              <Toolbar>
                <IconButton onClick={() => toggleFeature("qjson_editor")}>
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>

                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1 }}
                >
                  Editor&apos;s Toolbar
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={userFeatures.questionnnaires_test}
                      onClick={() => toggleFeature("questionnnaires_test")}
                    />
                  }
                  label="Questionnaires mode:TEST"
                />

                <Button onClick={() => window.qj.context(true)}>Log</Button>

                <Button
                  onClick={() =>
                    window.qj.hook({
                      type: "Delete",
                      context: ["answers"],
                    })
                  }
                >
                  Clear
                </Button>
              </Toolbar>
            </AppBar>
          )}

          <header>
            {isAuthenticated && (
              <div style={{ position: "absolute", right: 0 }}>
                <Button
                  id="session-button"
                  aria-controls="session-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  {user.name}
                </Button>
                <Menu
                  id="session-menu"
                  aria-labelledby="session-button"
                  anchorEl={sessionMenuEl}
                  open={sessionMenuOpen}
                  onClose={handleClick}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={signout}>Logout</MenuItem>
                </Menu>
              </div>
            )}

            <a
              className="logo"
              href={
                questionnaireData.data.questionnaire.properties.logo_link_url
              }
            >
              <img
                src={questionnaireData.data.questionnaire.properties.logo_url}
                alt="Jurisdiction's Logo"
              />
            </a>
          </header>
          <h1>
            {questionnaireData.data.questionnaire.properties.locale.en.title}
          </h1>
          <QuestionnairesProvider
            qjson={questionnaireData.data.questionnaire}
            questionId={match.params.questionId}
            context={context}
            prefix={`com.hostcompliance.questionnaire${
              match.params.jurisdictionId
                ? "." + match.params.jurisdictionId
                : ""
            }.${match.params.questionnaireId}`}
            goBack={goBack}
          ></QuestionnairesProvider>
        </div>
      )}
    </div>
  );
}

export default withApollo(View);
