import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import parser from "./parser";

function View({ question, context }) {
  const [errors, setErrors] = useState({});
  const locale = question.properties.locale; // Locale data
  const input = parser.parse(question.input, context); // Input data
  const [values, setValues] = useState({
    amount: context.pick("answers.exemption.amount") || 0,
  });

  useEffect(() => {
    context.assign({ value: values });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const tempValues = { ...values, [name]: value };
    context.assign({ value: tempValues });
    setValues(tempValues);
  };

  const validate = () => {
    let tempErrors = {};
    if (input.required === true) {
      if (!values.amount) {
        tempErrors.amount = locale.validation_required;
      } else if (!/^\d+(\.\d{1,2})?$/.test(values.amount)) {
        tempErrors.amount = locale.validation_invalid;
      }
      setErrors(tempErrors);
    }
    return Object.keys(tempErrors).length === 0;
  };

  return (
    <Box
      dir={locale.dir}
      className="inputs inputs-text"
      display="flex"
      flexDirection="column"
    >
      <TextField
        type="number"
        name="amount"
        label={locale.name}
        value={values.amount}
        onChange={handleChange}
        onBlur={validate}
        required={input.required === true}
        InputProps={{ readOnly: input.readonly, inputProps: { step: "0.01" } }}
        placeholder={locale.name}
        style={{ marginBottom: 30, marginTop: 10 }}
        autoComplete="off"
        error={!!errors.amount}
        helperText={errors.amount}
        fullWidth
      />
      {input.description_key && (
        <TextField
          type="text"
          name={input.description_key}
          label={locale.description}
          value={values.description}
          onChange={handleChange}
          required={input.required}
          InputProps={{ readOnly: input.readonly }}
          placeholder={locale.description}
          style={{ marginBottom: 30, marginTop: 10 }}
          autoComplete="off"
          fullWidth
        />
      )}
    </Box>
  );
}

export default View;
