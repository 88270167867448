import React, { useEffect } from "react";
import {
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { namedArray, goTo } from "./util";
import parser from "./parser";

function View({ question, context }) {
  useEffect(() => {
    // TODO
  }, [context]);
  const renderItem = (item) => {
    if (item.format && typeof item.format === "string") {
      item.format =
        item.format?.toUpperCase() === "MMMM D, Y"
          ? "MMMM D, YYYY"
          : item.format;
    } else if (item.format && typeof item.format === "object") {
      item.format =
        context.pick(item.format?.var)?.toUpperCase() === "MMMM D, Y"
          ? "MMMM D, YYYY"
          : context.pick(item.format?.var)?.toUpperCase();
    }
    switch (item.type) {
      case "address": {
        const node = context.pick(item.value_key);
        let address;
        if (node) {
          if (node?.concatenated) {
            address = node.concatenated;
          } else if (node?.formatted) {
            address =
              node.formatted +
              (node.unit_number ? `, Unit ${node.unit_number}` : "");
          } else {
            address =
              `${node.line_1 ? node.line_1 : ""} ${
                node.line_2 ? node.line_2 : ""
              } 
              ${node.city ? node.city : ""} ${node.state ? node.state : ""} 
              ${node.postal_code ? node.postal_code : ""}` +
              (node.unit_number ? `, Unit ${node.unit_number}` : "");
          }
        } else {
          address = "No address";
        }

        return (
          <ListItemText
            primary={
              <Typography className="custom-primary-text">{address}</Typography>
            }
            secondary={
              <Typography className="custom-secondary-text">
                {
                  <span>
                    {question.properties.locale[item.locale_key]}
                    &nbsp;&middot;{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.com/maps/search/${address}`}
                      style={{ color: "#ff5240" }}
                    >
                      Open in Google Maps
                    </a>
                  </span>
                }
              </Typography>
            }
          />
        );
      }

      case "boolean": {
        return (
          <ListItemText
            primary={
              <Typography className="custom-primary-text">
                {context.pick(item.value_key) === true ? "Yes" : "No"}
              </Typography>
            }
            secondary={
              <Typography className="custom-secondary-text">
                {question.properties.locale[item.locale_key]}
              </Typography>
            }
          />
        );
      }

      case "date": {
        return (
          <ListItemText
            primary={
              <Typography className="custom-primary-text">
                {dayjs(context.pick(item.value_key, context)).format(
                  item.format
                )}
              </Typography>
            }
            secondary={
              <Typography className="custom-secondary-text">
                {question.properties.locale[item.locale_key]}
              </Typography>
            }
          />
        );
      }

      case "documentation": {
        // TODO
        // <span ng-repeat="(uploadId, upload) in vm.dotPick(i.value_key, vm.context)">
        //   <span ng-repeat="(fileId, file) in upload.files">
        //     {{ file.original_filename }}<span>, </span>
        //   </span>
        // </span>
        return (
          <ListItemText
            primary=""
            secondary={question.properties.locale[item.locale_key]}
          />
        );
      }

      case "eligibility": {
        // TODO: if unit.
        return (
          <ListItemText
            primary={
              <Typography className="custom-primary-text">
                {`${
                  context.pick(item.value_key, context).street_address ||
                  "No address"
                }, Unit ${context.pick(item.value_key, context).unit_number}`}
              </Typography>
            }
            secondary={
              <Typography className="custom-secondary-text">
                {question.properties.locale[item.locale_key]}
              </Typography>
            }
          />
        );
      }

      case "email": {
        return (
          <ListItemText
            primary={
              <Typography className="custom-primary-text">
                {
                  <a
                    href={`mailto:${context.pick(item.value_key)}`}
                    style={{ color: "#ff5240" }}
                  >
                    {context.pick(item.value_key)}
                  </a>
                }
              </Typography>
            }
            secondary={
              <Typography className="custom-secondary-text">
                {question.properties.locale[item.locale_key]}
              </Typography>
            }
          />
        );
      }

      case "listings": {
        //   <div ng-repeat="l in vm.dotPick(i.value_key, vm.context)">
        //     <div class="md-list-item-text">
        //       <h3 style="margin: 14px 0 0 0;">
        //         {{ $index + 1 }}/{{ ::vm.dotPick(i.value_key, vm.context).length }}.
        //         {{ l.value || l.webpage || l.website }} <!-- TECHDEBT: legacy formats -->
        //       </h3>
        //       <p class="documentation" ng-if="l.upload_id">
        //         Documentation: <strong>Upload</strong>
        //         <!-- <span ng-repeat="f in l.files.documentation.files">
        //           {{ f.original_filename }}<span ng-if="!$last">, </span>
        //         </span> -->
        //       </p>
        //
        //       <p ng-repeat="r in l.taxable_receipts" ng-if="l.taxable_receipts.length">
        //         {{ r.period.start_at | amDateFormat:'MMM YYYY' }}<span ng-if="r.period.end_at | amDifference:r.period.start_at:'days' > 87"> - {{ r.period.end_at | amDateFormat:'MMM YYYY' }}</span>
        //
        //         <span ng-if="r.amount">&middot; Receipts: {{ r.amount | currency:$:2 }}</span>
        //         <span ng-if="r.nights_occupied">&middot;
        //           Occupied:
        //           {{ r.nights_occupied }}<span ng-if="r.nights_available">/{{ r.nights_available }}</span>
        //         </span>
        //
        //         <span ng-if="r.avg_nightly_rate">Avg. Rate: {{ r.avg_nightly_rate }}</span>
        //       </p>
        //     </div>
        //   </div>
        return <span>TODO</span>;
      }

      case "period": {
        const period = context.pick(item.value_key, context);
        return (
          <ListItemText
            primary={
              <Typography className="custom-primary-text">
                {`${dayjs(period?.start_at).format(item.format)} - ${dayjs(
                  period?.end_at
                ).format(item.format)}`}
              </Typography>
            }
            secondary={
              <Typography className="custom-secondary-text">
                {question.properties.locale[item.locale_key]}
              </Typography>
            }
          />
        );
      }

      case "tel":
      case "telephone": {
        return (
          <ListItemText
            primary={
              <Typography className="custom-primary-text">
                {item.value_key && context.pick(item.value_key, context)}
                {item.value &&
                  parser.getContextValue(
                    parser.parse(item.value, context),
                    context
                  )}
              </Typography>
            }
            secondary={
              <Typography className="custom-secondary-text">
                {question.properties.locale[item.locale_key]}
              </Typography>
            }
          />
        );
      }

      case "text": {
        return (
          <ListItemText
            primary={
              <Typography className="custom-primary-text">
                {parser.getContextValue(
                  parser.parse(item.value, context),
                  context
                )}
              </Typography>
            }
            secondary={
              <Typography className="custom-secondary-text">
                {question.properties.locale[item.locale_key]}
              </Typography>
            }
          />
        );
      }
    }
  };

  return (
    <div>
      <List dense={true}>
        {question.input.groups
          .map((o) => {
            o.active = parser.parse(o.active, context);
            return o;
          })
          .filter((o) => o.active !== false)
          .map((g, i) => {
            g.items = namedArray(g)
              .map((o) => {
                o.active = parser.parse(o.active, context);
                return o;
              })
              .filter((o) => o.active !== false);

            return (
              <div key={`group-${i}`}>
                <ListSubheader
                  style={{
                    color: "rgba(0,0,0,0.54)",
                    backgroundColor: "rgb(250,250,250)",
                  }}
                >
                  {question.properties.locale[g.locale_key]}
                </ListSubheader>
                {g.items.map((item) => {
                  return (
                    <ListItem key={item.id} className={"summary-list-item"}>
                      {renderItem(item)}
                      {question.input.actionable &&
                        parser.parse(item.action, context) && (
                          <ListItemSecondaryAction>
                            <IconButton
                              aria-label="Edit"
                              onClick={() => {
                                goTo(item.action, context);
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} width={"20px"} />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                    </ListItem>
                  );
                })}
              </div>
            );
          })}
      </List>
    </div>
  );
}

export default View;
