// TODO: input
import React, { useState, useEffect } from "react";
import { Toolbar, Button, Typography, Tooltip, Box } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import dayjs from "dayjs";
import Approvals from "./approvals";
import parser from "./parser";

function View({ question, context, showFormErrors }) {
  const [item] = useState(
    context.pick(
      parser.interpolate(question.input.item, context.get()),
      context
    )
  );
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [currentFile, setCurrentFile] = useState();
  const approvals = context.pick("data.approvals");

  useEffect(() => {
    context.assign({
      canSubmit: false,
    });
  }, []);

  useEffect(() => {
    if (item) {
      setCurrentFile(item?.files[currentFileIndex]);
      const matchingApproval = approvals.find(
        (approval) => approval.key === item.extra.key
      );
      if (matchingApproval) {
        const previousData = context.pick("value") || {};
        const updatedData = {
          ...previousData,
          status: matchingApproval.status || false,
          explanation: matchingApproval.explanation || "",
          id: matchingApproval.id,
        };

        context.assign({
          value: updatedData,
          canSubmit: true,
        });
      }
    }
  }, [item, currentFileIndex]);

  const onStatusChange = (statusValue) => {
    let previousData = context.pick("value");
    context.assign({
      value: {
        ...(previousData || {}),
        status: statusValue,
      },
      canSubmit: true,
    });
  };

  const onExplanationChange = (details) => {
    let previousData = context.pick("value");
    if (previousData) {
      context.assign({
        value: {
          ...previousData,
          explanation: details,
        },
      });
    }
  };

  const selectFile = (index) => {
    setCurrentFileIndex(index);
  };

  const mimeName = (mimetype) => {
    return mimetype.split("/")[0];
  };

  const renderPreview = (preview) => {
    const mimeType = mimeName(preview.mimetype);
    switch (mimeType) {
      case "application/pdf":
        return (
          <Typography>
            PDF preview not available. Please download to review.
          </Typography>
        );
      case "image":
        return (
          <img
            src={preview.url}
            className="preview-image"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        );
      case "audio":
        return (
          <audio controls src={currentFile?.url}>
            Your browser does not support audio.
          </audio>
        );
      case "video":
        return (
          <video controls>
            <source src={currentFile?.url} type={currentFile?.mimetype} />
            Your browser does not support video.
          </video>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      sx={{ paddingTop: 1, paddingRight: 2, marginRight: 2 }}
    >
      {currentFile && (
        <Box flex={{ md: "1 0 70%" }}>
          <Toolbar
            variant="dense"
            sx={{
              backgroundColor: "#303030",
              color: "#ffffffde",
              padding: "10px",
            }}
          >
            <Button
              disabled={currentFileIndex === 0}
              onClick={() => selectFile(currentFileIndex - 1)}
            >
              <ChevronLeft
                sx={{ color: currentFileIndex === 0 ? "#ffffff42" : "#ffffff" }}
              />
            </Button>
            <Typography>
              {currentFileIndex + 1}/{item.files.length}, Uploaded{" "}
              {dayjs(currentFile?.uploaded_at).format("MMMM D, YYYY")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title={currentFile.original_filename}>
              <Button
                href={currentFile.url}
                target="_blank"
                download
                className="download-button"
              >
                Download
              </Button>
            </Tooltip>
            <Button
              disabled={currentFileIndex + 1 === item.files.length}
              onClick={() => selectFile(currentFileIndex + 1)}
            >
              <ChevronRight
                sx={{
                  color:
                    currentFileIndex + 1 === item.files.length
                      ? "#ffffff42"
                      : "#ffffff",
                }}
              />
            </Button>
          </Toolbar>

          <Box sx={{ p: 2 }}>
            {!currentFile.previews.length ? (
              <Typography>
                No preview available, please{" "}
                <a
                  href={currentFile?.url}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  download {currentFile?.original_filename}
                </a>{" "}
                to review.
              </Typography>
            ) : (
              currentFile.previews.map((preview, index) => (
                <div key={index}>{renderPreview(preview)}</div>
              ))
            )}
          </Box>
        </Box>
      )}
      {question.properties.aside && (
        <Box flex={{ md: "1 0 30%" }} sx={{ paddingLeft: 3, marginLeft: 2 }}>
          <Approvals
            question={question}
            context={context}
            onExplanationChange={onExplanationChange}
            onStatusChange={onStatusChange}
            showFormErrors={showFormErrors}
            data={context.pick("value")}
          ></Approvals>
        </Box>
      )}
    </Box>
  );
}

export default View;
