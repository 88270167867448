import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  FormHelperText,
  Box,
  Typography,
} from "@mui/material";
import parser from "./parser";

function Approvals({
  question,
  context,
  onExplanationChange,
  onStatusChange,
  showFormErrors,
  data,
}) {
  const [status, setStatus] = useState("");
  const [explanation, setExplanation] = useState("");
  const isReadOnly = parser.parse(question.input.readonly, context);
  const locale = question.properties.locale;
  const auditStatus = context.pick("data.audit.status");

  useEffect(() => {
    if (data) {
      setStatus(data.status);
      setExplanation(data.explanation || "");
    }
  }, [data]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    // Update value.status by calling method from the Parent component
    onStatusChange(event.target.value);
  };

  const handleExplanationChange = (event) => {
    setExplanation(event.target.value);
    // Update value.explanation
    onExplanationChange(event.target.value);
  };

  return (
    <Box>
      {isReadOnly ? (
        <Typography>{auditStatus}</Typography>
      ) : (
        <RadioGroup value={status} onChange={handleStatusChange}>
          <FormControlLabel
            value="ACCEPTED"
            control={
              <Radio
                sx={{
                  color: "#0000008a",
                  "&.Mui-checked": { color: "#e5163dde" },
                }}
              />
            }
            label={locale.review_accepted}
          />
          <FormControlLabel
            value="REJECTED"
            control={
              <Radio
                sx={{
                  color: "#0000008a",
                  "&.Mui-checked": { color: "#e5163dde" },
                }}
              />
            }
            label={locale.review_rejected}
          />
        </RadioGroup>
      )}
      {showFormErrors && !isReadOnly && !status && (
        <FormHelperText error>Required</FormHelperText>
      )}

      <TextField
        label={locale.review_explanation}
        InputLabelProps={{
          sx: {
            color: status === "REJECTED" ? "#d50000" : "#00000099",
          },
        }}
        multiline
        rows={5}
        value={explanation}
        onChange={handleExplanationChange}
        required={status === "REJECTED"}
        name="explanation"
        id="ref-explanation"
        autoComplete="off"
        fullWidth
        style={{ marginTop: "20px", marginBottom: "10px" }}
        inputProps={{
          readOnly: isReadOnly,
        }}
      />

      {locale.review_explanation_hint && (
        <FormHelperText>{locale.review_explanation_hint}</FormHelperText>
      )}
      {status === "REJECTED" && (
        <FormHelperText sx={{ color: "#d50000", fontSize: "14px" }}>
          {locale.validation_required}
        </FormHelperText>
      )}
    </Box>
  );
}

export default Approvals;
