const dot = require("dot-object");
import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  TextField,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SignaturePad from "react-signature-pad-wrapper";
import { getData, questionnaireVersion1 } from "./util";

function View({ question, context }) {
  const signaturePadRef = useRef(null);
  const isQJSONVersion1 = questionnaireVersion1.includes(
    getData("questionnaireID")
  );
  const imageLabel = isQJSONVersion1 ? "image" : "image_url";
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: {
      name: "",
      [imageLabel]: "",
    },
  });
  const [showSignatureRequired, setShowSignatureRequired] = useState(true);

  useEffect(() => {
    context.assign({
      canSubmit: false,
    });
  }, []);

  const validate = () => {
    let tmp = { ...formErrors };

    //console.warn(formValues);
    // TODO // any additional validation...
    // if (!formValues.output && !formValues.output.image_url && formValues.output.image_url === '') {
    //   tmp.image_url = question.properties.locale.validation_required;
    // } else {
    //   delete tmp.image_url;
    // }

    setFormErrors(tmp);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let values = { ...formValues };
    dot.str(name, value, values);
    setFormValues(values);
    validate();
    context.assign({
      value: values.output,
    });
  };

  //
  let signaturePad;
  const onSignatureBegin = () => {
    if (!signaturePad) signaturePad = signaturePadRef.current;
  };

  const clearSignature = () => {
    if (!signaturePad) signaturePad = signaturePadRef.current;
    signaturePad.clear();
    setShowSignatureRequired(true);
    context.assign({
      canSubmit: false,
    });
    handleInputChange({
      target: {
        name: isQJSONVersion1 ? "output.image" : "output.image_url",
        value: "",
      },
    });
  };

  const onSignatureEnd = () => {
    if (!signaturePad) signaturePad = signaturePadRef.current;
    setShowSignatureRequired(false);
    context.assign({
      canSubmit: true,
    });

    handleInputChange({
      target: {
        name: isQJSONVersion1 ? "output.image" : "output.image_url",
        value: signaturePad?.toDataURL(),
      },
    });
  };

  return (
    <div className="inputs inputs-signature">
      <FormControl error fullWidth>
        <TextField
          id="name"
          name="output.name"
          label={question.properties.locale.name}
          variant="filled"
          value={formValues.output.name}
          onChange={handleInputChange}
          required
          autoFocus
          inputProps={{
            inputref: "ref-name",
          }}
          autoComplete="off"
          aria-describedby="name-helper-text"
        />
        <FormHelperText id="name-helper-text" style={{ fontSize: "0.9rem" }}>
          {formErrors.name}
        </FormHelperText>
      </FormControl>
      <Typography className="signature-label">Signature</Typography>
      <SignaturePad
        ref={signaturePadRef}
        options={{
          onEnd: onSignatureEnd,
          onBegin: onSignatureBegin,
        }}
      />
      <input
        id="image_url"
        name={isQJSONVersion1 ? "output.image" : "output.image_url"}
        defaultValue={
          isQJSONVersion1
            ? formValues.output.image
            : formValues.output.image_url
        }
        type="hidden"
        aria-describedby="image_url-helper-text"
      />
      {showSignatureRequired && (
        <FormHelperText error className="error-message">
          Please enter a valid Signature
        </FormHelperText>
      )}

      <nav style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          onClick={clearSignature}
          className="clear-signature"
          startIcon={<ClearIcon fontSize="1.2rem" style={{ color: "#fff" }} />}
        >
          {question.properties.locale.clear}
        </Button>
      </nav>
    </div>
  );
}

export default View;
